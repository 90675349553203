/*------------------------------------*\
    #CORE TYPOGRAPHY
\*------------------------------------*/


h1 {
  @include font-size($font-sizes-xl);
  @extend .font-var-semibold;
  letter-spacing: .05em;
  .text--content & {
    text-transform: uppercase;
  }
  .hero__content & {
    @include font-size($font-sizes-xxl);
  }

}

.hero h1 {

}

h2 {
  @include font-size($font-sizes-lg);
  @extend .font-var-semibold;
  @include margin-bottom($spacing-sm-responsive);
  span {
    display: block;
    @include font-size($font-sizes-md);
    @extend .font-body-light;
    @include margin-top($spacing-sm-responsive);
  }
}

h3 + h2 {
  @media (max-width: $screen-md-max) {
    margin-top: 1em;
  }
}

p + h2 {
  @include margin-top($spacing-md-responsive);
}

.les-davids h2 {
  @include font-size($font-sizes-lg-alt);
  @include margin-bottom($spacing-md-responsive);
  @media (max-width: $screen-xs-max) {
    @include padding-top($spacing-md-responsive);
  }
}

.header--wrapper h2 {
  padding-top: 0;
}

h3,
h4 {
  @include font-size($font-sizes-xs);
  @extend .font-body-bold;
  text-transform: uppercase;
  letter-spacing: .1em;
}

h3 {
  @include margin-bottom($spacing-md-responsive);
}

h4 {
  @include margin-top($spacing-lg-responsive);
  @include margin-bottom($spacing-sm-responsive);
}

h5 {
  @include font-size($font-sizes-md);
  @extend .font-body-bold;
  @include margin-top($spacing-md-responsive);
}

h6 {
  @include font-size($font-sizes-sm);
  @extend .font-body-light;
}

p + p {
  margin-top: .5em;
}
p.intro {
  @include font-size($font-sizes-md-alt);
  @extend .font-var-semibold;
}

h3 + p.intro {
  @include font-size($font-sizes-md);
}

p.large {
  @include font-size($font-sizes-md);
  @extend .font-body-light;
  @include margin-bottom($spacing-md-responsive);
  a {
    @include font-size($font-sizes-md);
  }
}

p,
ul {
  @include font-size($font-sizes-sm);
  @extend .font-body-light;
}

p.small {
  @include font-size($font-sizes-xs);
  @extend .font-body-bold;
  @include margin-top($spacing-md-responsive);
  a {
    @include font-size($font-sizes-xs);
  }
}

a {
  color: $base-color;
  @include font-size($font-sizes-sm);
  @extend .font-body-light;
  display: inline-block;
  border-bottom: $border-width-sm solid $base-color;
  text-decoration: none;
  transition: all .2s ease-in-out;
  @include link-active-styles {   // Mixin for interactions (hover,focus,active)
    border-bottom: $border-width-sm solid transparent;
  }
  &.large {
    @include font-size($font-sizes-md);
    display: inline;
  }
}

ul {
  @include margin-top($spacing-md-responsive);
  text-align: left;
  padding-left: 20px;
  margin-left: 0;
  li {
    list-style: none;
    position: relative;
    &:before {
      content: "–";
      position: absolute;
      left: -20px;
      top: -2px;
    }
  }
}


//----------------------------------


blockquote p{
   @include font-size($font-sizes-lg);
   @extend .font-var-semibold;
   text-align: center;
}

em,
i{
  font-style: italic;
}

b,
strong{
  font-weight: bold;
}

p sub, p sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

p sup {
  top: -0.5em;
}

p sub {
  bottom: -0.25em;
}

p small{
  @include font-size($font-sizes-sm);
}

p abbr {
  border-bottom: 1px dotted $base-color;
  cursor: help;
}

p q,
p cite{
 &:before{
    content:'"';
 }
 &:after{
    content:'"';
 }
}

p mark{
  background-color: $action;
  color: $body-color;
}

p code,
p samp{
  font-family: monospace;
  @include font-size($font-sizes-md);
}

pre{
  font-family: monospace;
  @include font-size($font-sizes-md);
}

hr{
  background: $base-color;
  height: 4px;
  border: none;
  margin: 0;
}

figcaption{
  @include font-size($font-sizes-sm);
  @include margin-top($spacing-xs-responsive);
}

dl{
  dt{
    @include font-size($font-sizes-sm);
    @include margin-bottom($spacing-xs-responsive);
    &:first-of-type{
      @include padding-top($spacing-sm-responsive);
      border-top: $border-width-sm $base-color solid;
    }
  }
  dd{
    padding-bottom: $spacing-sm;
    margin-bottom: $spacing-sm;
    border-bottom: $border-width-sm $base-color solid;
  }
}

ol, ul, pre, dl                                         {@include font-size($font-sizes-md);}
ul, ol                                                  {@include nobullet;}
ul ul                                                   {@include font-size($font-sizes-md);}
ol ol                                                   {@include font-size($font-sizes-md);}
li                                                      {line-height: inherit;}
input, textarea, label, select                          {line-height: $line-height-form}



/*------------------------------------*\
    #BOOTSTRAP TYPOGRAPHY
\*------------------------------------*/


//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  $font-family-body;
$font-family-serif:       $font-family-var;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   $font-family-mono;
$font-family-base:        $font-family-body;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        $line-height-form;
$font-size-base:          $font-size-sm;
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px
