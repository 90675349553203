/*------------------------------------*\
    #ATOMS
\*------------------------------------*/



/*------------------------------------*\
    #colors
\*------------------------------------*/


.color-blue-1 {
  background-color: $blue-1;
}
.color-blue-2 {
  background-color: $blue-2;
}

.color-blue-3 {
  background-color: $blue-3;
}

.color-green-1 {
  background-color: $green-1;
}
.color-green-2 {
  background-color: $green-2;
}
.color-green-3 {
  background-color: $green-3;
}
.color-green-4 {
  background-color: $green-4;
}
.color-green-5 {
  background-color: $green-5;
}

.color-red-1 {
  background-color: $red-1;
}

.color-red-2 {
  background-color: $red-2;
}

.color-red-3 {
  background-color: $red-3;
}

.color-pink-1 {
  background-color: $pink-1;
}

.color-orange-1 {
  background-color: $orange-1;
}

.color-orange-2 {
  background-color: $orange-2;
}

.color-orange-3 {
  background-color: $orange-3;
}

.color-orange-4 {
  background-color: $orange-4;
}

.color-purple-1 {
  background-color: $purple-1;
}

.color-gray-1 {
  background-color: $gray-1;
}

.color-gray-2 {
  background-color: $gray-2;
}





/*------------------------------------*\
    #global
\*------------------------------------*/


/**
 * Global > animations
 */

.animate-fade {
  transition: opacity 0.3s ease-out;
  &:hover {
    opacity: 0;
  }
}

.animate-move {
  > .demo-shape {
    transition: all 0.8s ease-in-out;
  }
}


/**
 * Global > visibilty
 */

// Completely remove from the flow and screen readers.
.is-hidden {
  display: none !important;
  visibility: hidden !important;
}

// Completely remove from the flow but leave available to screen readers.
.is-vishidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
}

// Display Classes
.hide-small {
  @media all and (max-width: $screen-md) {
    display: none;
  }
}

.hide-med {
  @media all and (min-width: $screen-md) and (max-width: $screen-lg) {
    display: none;
  }
}

.hide-large {
  @media all and (min-width: $screen-lg) {
    display: none;
  }
}



/*------------------------------------*\
    #images
\*------------------------------------*/


img, video, object {
  max-width: 100%;
  height: auto;
}

img{
  vertical-align: middle;
}



/*------------------------------------*\
    #Buttons
\*------------------------------------*/

.btn {
  border: $border-width-sm solid $base-color;
  background: none;
  border-radius: 0;
  color: $base-color;
  @extend .font-body-bold;
  padding: $spacing-md;
  &:hover {
    background-color: $base-color;
    color: $body-color;
    border: $border-width-sm solid $body-color;
  }
}

.btn__small {
  @include font-size($font-sizes-xs);
  padding: $spacing-xs;
  text-transform: uppercase;
  @include margin-top($spacing-lg-responsive);
}
