/*------------------------------------*\
    #MOLECULES
\*------------------------------------*/



/*------------------------------------*\
    #navigation
\*------------------------------------*/


/**
 * navigation > header
 */

.header-primary {
  background-color: $base-color;
  .header--wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 85px;
    @media (max-width: $screen-xs-max) {
      height: 55px;
      .header--left,
      .header--right {
        padding-top: $spacing-sm;
        padding-bottom: $spacing-sm;
      }
      padding-top: $spacing-sm;
      padding-bottom: $spacing-sm;
    }
  }
  h1 {
    font-size: 0;
    line-height: 0;
  }
  h2 {
    color: $body-color;
    @include font-size($font-sizes-sm);
    @include margin-top($spacing-xs-responsive);
    text-align: center;
    @media (max-width: $screen-xs-max) {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
  button {
    background: none;
    border: none;
    padding: 0;
    &:focus {
      outline: none;
    }
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    display: inline-block;
    &:before {
      content: none;
    }
  }
  a,
  button {
    @extend .font-body-bold;
    color: $black;
    transition: all .3s ease-in-out;
    @include font-size($font-sizes-xs);
    letter-spacing: .06em;
    text-transform: uppercase;
    &:hover{
      color: $body-color;
    }
    &.active {
      color: $blue;
    }
  }
}

.header--left {
  button,
  .lang {
    display: inline-block;
  }
  .lang li:not(:last-of-type):after {
    content: "—";
    color: $black;
  }
  .current a{
    color: $body-color;
  }
}

.header-primary .hamburger,
.navigation-secondary li:not(:last-of-type) {
  margin-right: $spacing-lg;
  @media (max-width: $screen-xs-max) {
    margin-right: $spacing-md;
  }
}

button.hamburger {
  padding: 11px; // pour etre sur de bien cliquer
}

.header--center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: $screen-sm-max) {
    img {
      width: 240px;
    }
  }
  @media (max-width: $screen-xs-max) {
    line-height: 0;
    text-align: center;
    width: 60%;
    img {
      width: 100%;
      max-width: 240px;
    }
  }
}

/**
 * navigation > menu-open
 */


.menu-open::-webkit-scrollbar {
  display: none;
}

.menu-open--wrapper {
  position: relative;
}
.menu-open {
  display: none;
  background-color: $base-color;
  position: absolute;
  top: 0;
  left: -3px;
  z-index: 99;
  max-height: calc(100vh - 85px);
  overflow-y: scroll;
  h2,
  a {
    color: $body-color;
  }
  h2,
  h2 a {
    @include font-size($font-sizes-xs);
    @extend .font-body-light;
    text-transform: uppercase;
    @include margin-bottom($spacing-xs-responsive);
  }
  a {
    @include font-size($font-sizes-md);
    @extend .font-body-bold;
    border: none;
    &:hover {
      color: $black;
    }
  }
  ul {
    padding: 0;
    margin: 0;
  }
  a img {
    display: block;
    @media (max-width: $screen-xs-max) {
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.menu--block {
  @include margin-bottom($spacing-lg-responsive);
  @media (max-width: $screen-xs-max) {
    &,
    a {
      text-align: center;
      display: block;
    }
  }
  &:first-of-type {
    @include margin-top($spacing-lg-responsive);
  }
  body.les-davids & {
    .link--small {
      @include font-size($font-sizes-xs);
      @extend .font-body-light;
      text-transform: uppercase;
      @include margin-bottom($spacing-xs-responsive);
    }
    h2:before {
      content: "—";
      display: block;
      @include font-size($font-sizes-md);
      @extend .font-body-bold;
      @include margin-bottom($spacing-sm-responsive);
    }
  }
  ul + h2 {
    margin-top: 1em;
  }
}


/**
 * navigation > locations-open
 */

.locations-open {
  display: none;
  text-align: center;
  background-color: $base-color;
  a {
    color: $blue;
    display: block;
    &.locations__special {
      color: $body-color;
    }
    &:hover .hgroup{
      color: $black;
    }
  }
  & > .row {
    @media (min-width: $screen-md-min) {
      height: 500px;
    }
  }
  h2 {
    @include font-size($font-sizes-lg-alt);
  }
  h3 {
    color: $blue;
    @include font-size($font-sizes-sm);
    @extend .font-body-light;
    margin-top: .7em;
  }
  button.icon-close {
    top: 20px;
    right: 20px;
  }
}

.locations-open .locations__special {
  a,
  h3{
   color: $gold;
  }
  h3 {
    @include font-size($font-sizes-sm);
  }
}


.locations--block {
  height: 100%;
  @media (max-width: $screen-sm-max) {
    padding-top: $spacing-lg;
    padding-bottom: $spacing-lg;
  }
  @media (min-width: $screen-sm-min) {
    width: 100%;
  }
  @media (min-width: $screen-md-min) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    margin: $spacing-lg 0;
    width: 20%;
  }
  &:not(:last-of-type) {
    @media (min-width: $screen-md-min) {
      border-right: 2px dotted $blue;
    }
    @media (max-width: $screen-sm-max) {
      border-bottom: 2px dotted $blue;
    }
    &:nth-of-type(4) {
      border-color: $gold;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    height: 20%;
    @media (max-width: $screen-sm-max) {
      @include margin-top($spacing-lg-responsive);
      @include margin-bottom($spacing-lg-responsive);
    }
  }
  li {
    text-align: center;
    &:before {
      content: none;
    }
  }
  .hgroup {
    transition: all .3s ease-in-out;
    height: 40%;
  }
  h2 a {
    @extend h2;
    line-height: 1 !important;
  }
  a:hover {
    color: $black;
  }
  img{
    width: 100%;
    height: 84px;
  }
}


/**
 * navigation > booking-open
 */

 .booking-open {
  display: none;
  text-align: center;
  background-color: $base-color;
  color: $body-color;
  @include padding-top($spacing-xl-responsive);
  @include padding-bottom($spacing-xl-responsive);
  .request-button {
    @include font-size($font-sizes-lg);
    @extend .font-var-semibold;
    @include padding-top($spacing-lg-responsive);
    @include padding-bottom($spacing-lg-responsive);
    @include margin-bottom($spacing-xl-responsive);
  }
}

.fixed .booking-open.active .icon-close{
  display: none;
}

.booking-contact{
  h3 {
    @include font-size($font-sizes-sm);
    text-transform: none;
    margin-bottom: 0;
    &:after {
      content: "—";
      display: block;
      @extend .font-body-light;
    }
  }
  a {
    color: $gold;
    border-bottom: $border-width-sm solid $gold;
    @include font-size($font-sizes-sm);
  }
  p {
    @include font-size($font-sizes-sm);
  }
  .offset{
    @media (min-width: $screen-sm-min) {
      margin-left: 12.5%;
    }
  }
  .booking--block{
    @media (max-width: $screen-xs-max) {
      @include margin-bottom($spacing-lg-responsive);
    }
  }
}


.request-button {
  color: $blue;
  border: $border-width-sm solid $blue;
  text-align: center;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  cursor: pointer;
  background: none;
  transition: all .3s ease-in-out;
  &:focus {
    outline: none;
  }
  &:before {
    content: "→";
    margin-right: .6em;
    display: inline-block;
    transition: color .3s ease-in-out;
  }
  &:hover {
    background-color: $blue;
    color: $base-color;
    &:before {
      color: $base-color;
    }
  }
}

.content-link.request-button {
  color: $base-color;
  border-color: $base-color;
  &:hover {
    background-color: $base-color;
    color: $gold;
    border-color: $gold;
    &:before {
      color: $gold;
    }
  }
}

.menu-link.request-button {
  &:hover {
    background: none;
    color: $black;
  }
  &:before {
    content: none;
  }
}


/*------------------------------------*\
    #components
\*------------------------------------*/

body.fixed {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
}

.booking-modal--wrapper {
  display: none;
  height: 100vh;
  width: 103%; // 100% + les marges de la grille
  position: absolute;
  z-index: 998;
  top: 0;
  left: 0;
  background-color: rgba($base-color, .5);
}

.booking-modal {
  position: absolute;
  z-index: 999;
  max-height: 90vh;
  overflow-y: scroll;
  top: 50%;
  transform: translateY(-50%);
  background-color: $base-color;
  border: $border-width-sm solid $blue;
  padding: 3% 5%;
  @media (max-width: $screen-xs-max) {
    padding: 10% 5%;
  }
  position: relative;
  h2 {
    color: $blue;
    @include font-size($font-sizes-sm);
    @extend .font-body-bold;
    @include margin-bottom($spacing-md-responsive);
  }
  fieldset {
    @include margin-bottom($spacing-md-responsive);
  }
  label {
    color: $blue;
    @include font-size($font-sizes-xs);
    @extend .font-body-bold;
    text-transform: none;
    margin-bottom: 0;
  }
  .form-control {
    border: none;
    border-bottom: $border-width-sm*2 dotted $blue;
    padding-left: 0;
    padding-right: 0;
  }
  .form-inline .form-group {
    width: calc(50% - 14px);
    label,
    input {
      width: 100%;
    }
    &:last-of-type {
      margin-left: 20px;
      @media (max-width: $screen-xs-max) {
        margin-left: 0;
      }
    }
    @media (max-width: $screen-xs-max) {
      width: 100%;
    }
  }
  .form-group {
    @include margin-bottom($spacing-lg-responsive);
    color: $black;
  }
  input.flatpickr-input {
    @extend .form-control;
  }
  button[type=submit].form-control {
    @extend .request-button;
    @include padding-top($spacing-sm-responsive);
    @include padding-bottom($spacing-sm-responsive);
    height: auto;
    @extend .font-body-bold;
  }
  .booking-form {
    position: relative;
  }
  .message {
    display: none;
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: $base-color;
    .message--box {
      height: 100%;
      display: flex;
    }
    h2 {
      @extend .font-var-semibold;
      @include font-size($font-sizes-lg);
      margin: auto;
      text-align: center;
    }
  }
  // corrections wordpress
  .wpcf7-form-control-wrap.text {
    padding: 0;
  }
  a {
    display: inline;
  }
  .cancel-button {
    border: $border-width-sm solid $blue;
    color: $blue;
    @extend .font-body-bold;
    height: auto;
    width: auto;
    padding-left: 2em;
    padding-right: 2em;
    @include font-size($font-sizes-xs);
    @include padding-top($spacing-sm-responsive);
    @include padding-bottom($spacing-sm-responsive);
    @include margin-top($spacing-sm-responsive);
    @include margin-bottom($spacing-lg-responsive);
  }
}

.booking-modal{
  .booking-contact{
    @include margin-top($spacing-lg-responsive);
    @include margin-bottom($spacing-lg-responsive);
  }
}

.wpcf7-form{
  @include padding-top($spacing-lg-responsive);
  border-top: $border-width-sm solid $blue;
}

button.icon-close {
  display: block;
  position: absolute;
  z-index: 9999;
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  background: none;
  &:focus {
    outline: none;
  }
  top: 20px;
  right: 20px;
  @media (max-width: $screen-xs-max) {
    right: 5%;
    top: 2%;
  }
}

.wpcf7-not-valid-tip {
  @extend .font-body-light;
  @include margin-top($spacing-xs-responsive);
}

.wpcf7-response-output {
  background-color: $red-1;
  border: none !important;
  width: 100%;
  margin: 1em 0 !important;
  padding: 1em !important;
  @extend .font-body-bold;
  text-align: center;
}

.wpcf7-response-output.wpcf7-mail-sent-ok {
  background-color: $green-1;
}

.flatpickr-calendar {
  @extend .font-body-light;
  span.today {
    border-color: $blue;
  }
  span.selected {
    background: $blue;
    border-color: $blue;
  }
  span.cur_month {
    @extend .font-body-bold;
  }
}

.booking-contact{
  color: $body-color;
}

/*------------------------------------*\
    #texts
\*------------------------------------*/


/**
 * texts > block text
 */

.text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  .text--content {
    width: 100%;
    text-align: center;
    h3:first-of-type {
      @media (min-width: $screen-sm-min) {
        position: absolute;
        top: $spacing-md;
        right: 0;
        left: 0;
      }
    }
    @media (max-width: $screen-md-max) {
      h3 + p {
        @include padding-top($spacing-md-responsive);
      }
    }
  }
}

/**
 * texts > section press
 */

.section-press {
  @include margin-top($spacing-md-responsive);
  time,
  h6 {
    display: inline-block;
  }
  time {
    @include font-size($font-sizes-xs);
    @extend .font-body-bold;
    text-transform: uppercase;
    letter-spacing: .1em;
    margin-bottom: 0;
    &:after {
      content: " — ";
    }
  }
  a {
    @include font-size($font-sizes-sm);
    @extend .font-body-bold;
    display: inline;
  }
}



/*------------------------------------*\
    #images
\*------------------------------------*/


.icon--block {
  display: inline-block;
  @include margin-top($spacing-lg-responsive);
  @include margin-bottom($spacing-lg-responsive);
  text-align: center;
}

.icon-big {
  width: 50%;
  max-width: 200px;
}

.icon-medium {
  width: 20%;
  max-width: 200px;
}

.icon-chrono {
  @include font-size($font-sizes-md);
  @extend .font-body-light;
  @include margin-top($spacing-xs-responsive);
  margin-bottom: 0;
  &:before {
    content: " ";
    display: inline-block;
    width: 30px;
    min-height: 21px;
    background-image: url('../images/chrono.svg');
    background-position: left center;
    background-repeat: no-repeat;
  }
}


/*------------------------------------*\
    #gallery
\*------------------------------------*/

.slide--wrapper,
.slide--item {
  width: 100%;
  height: 100%;
  display: block;
}

.flickity-prev-next-button {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  svg {
    display: none;
  }
  &:hover {
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
  }
  &:not(:hover) {
    opacity: 1;
  }
  &:disabled {
    opacity: .3;
  }
  path {
    stroke: $white;
    stroke-width: 10px;
  }
}

.flickity-prev-next-button.previous {
  background-image: url('../images/arrow-left.svg');
}

.flickity-prev-next-button.next {
  background-image: url('../images/arrow-right.svg');
}



/*------------------------------------*\
    #blocks
\*------------------------------------*/

.download {
  position: relative;
}
.download--box {
  width: 100%;
  @media (min-width: $screen-sm-min) {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
.download--link {
  padding: $spacing-sm;
  background-color: $base-color;
  color: $black;
  width: 100%;
  text-align: center;
}
.download .btn {
  @include margin-top($spacing-lg-responsive);
  @include margin-bottom($spacing-lg-responsive);
}

// download link color
.download--link {
  color: $gold;
  &:hover {
    background-color: $gold;
    color: $base-color;
  }
}

@media (min-width: $screen-lg-min) {
  .btn {
    position: absolute;
    bottom: $spacing-md;
    left: 50%;
    transform: translateX(-50%);
  }
}

.download--link:before {
  content: "";
  height: 15px;
  width: 7px;
  display: inline-block;
  margin-right: $spacing-sm;
  background: url("../images/icon-download.svg") no-repeat center center;
}

.download--link:hover:before {
  background: url("../images/icon-download-alt.svg") no-repeat center center;
}

// block media
.block-media {
  .media {
    @extend .cover;
    height: 66.66%;
    width: 100%;
    @media (max-width: $screen-md-max) {
      min-height: 800px;
    }
    @media (max-width: $screen-xs-max) {
      min-height: 600px;
    }
  }
  .text {
    height: 33.33%;
    text-align: center;
    display: block;
  }
}

// block person
.block-person .content{
  @extend .cover;
  color: $body-color;
  .text {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .btn {
    color: $body-color;
    border-color: $body-color;
    position: static;
    transform: none;
  }
  h2 {
    @include margin-bottom($spacing-sm-responsive);
  }
  h3 {
    @include margin-bottom($spacing-xs-responsive);
    @include font-size($font-sizes-sm);
    @extend .font-body-light;
  }
}

// block map
.block-map {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  .block-map__content {
    text-align: center;
    padding: $spacing-sm;
    h2,
    p,
    a {
      color: $black;
    }
    h2 {
      @include font-size($font-sizes-md);
      @include margin-bottom($spacing-xs-responsive);
    }
    a {
      border-bottom: $border-width-sm $black solid;
      @include margin-top($spacing-sm-responsive);
      @extend h3;
    }
  }
}

// block contact
.block-contact__wrapper {
  @media (min-width: $screen-sm-min) {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
  }
}

.block-contact__element {
  text-align: center;
  @include padding-top($spacing-md-responsive);
  @include padding-bottom($spacing-md-responsive);
  .logo {
    width: 150px;
    max-width: 100%;
    @include margin-bottom($spacing-md-responsive);
  }
}

/*------------------------------------*\
    #Hero
\*------------------------------------*/

.hero {
  background-size: cover;
  background-position: center center;
}

.hero__content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  position: relative;
  padding: 10% 10% 15% 10%;
}

.hero__link {
  width: 100%;
  text-align: center;
  border: none;
  &:hover {
    border: none;
  }
}

.hero--home .hero__link {
  width: 40%;
  max-width: 300px;
  @media only screen
    and (max-width: $screen-sm-max)
    and (orientation: portrait) {
    width: 80%;
  }
  img {
    width: 100%;
  }
}

.hero__link--small {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 70px;
  @media only screen
    and (max-width: $screen-sm-max)
    and (orientation: portrait) {
    height: 100px;
  }
  @extend h3;
  border: none;
  background: url(../images/arrow-down.svg) no-repeat;
  background-position: center 30px;
  &:hover {
    border: none;
    background-position: center 40px;
    cursor: pointer;
  }
}

.hero--video {
  height: 100vh;
  overflow: hidden;
  .bgvid {
    @media (max-width: $screen-sm-max) {
      display: none;
    }
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
    object-fit: cover;
  }
}
