/*------------------------------------*\
    #ORGANISMS
\*------------------------------------*/



/*------------------------------------*\
    #section
\*------------------------------------*/


$article-width  : 100%;
$article-height : 100%;

@media (min-width: $screen-lg-min) {
  article.w-1.h-1,
  article.w-2.h-2 {
    @include aspect-ratio(4, 2.75);
  }
  article.w-2.h-1 {
    @include aspect-ratio(8, 2.75);
  }
  article.w-1.h-2 {
    @include aspect-ratio(4, 5.5);
  }
  article.w-1.h-3 {
    @include aspect-ratio(4, 8.25);
  }
  article.w-1.h-4 {
    @include aspect-ratio(4, 11);
  }
}

.cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @media (max-width: $screen-md-max) {
    @include aspect-ratio(4, 2.75);
    &.w-1.h-2 {
      @include aspect-ratio(4, 5.5);
    }
  }
}

.slide{
  @media (max-width: $screen-md-max) {
    @include aspect-ratio(4, 2.75);
  }
}

.block-person {
  @media (max-width: $screen-md-max) {
    @include aspect-ratio(4, 5.5);
  }
}

.map--wrapper {
  @media (max-width: $screen-md-max) {
    height: 60vh;
  }
}

article,
.header-wrapper,
.primary-footer,
section.hero {
  &:not(.lazyloaded) {
    opacity: 0;
  }
  &.lazyloaded {
    opacity: 1;
    transition: opacity .8s ease-in-out;
  }
}

.text {
  padding: 7%;
  @media (max-width: $screen-md-max) {
    padding: 20px 5% 40px 5%;
  }
  .text--content {
    max-width: 800px;
  }
  .btn:not(.btn__small) {
    @include margin-top($spacing-lg-responsive);
    @media (max-width: $screen-sm-max) {
      max-width: 100%;
      white-space: pre-wrap;
    }
  }
}

.w-3 .text--content {
  max-width: 100%;
  p {
    max-width: 900px;
    margin: auto;
  }
}

.content.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 260px;
  img {
    display: block;
    width: 50%;
    max-width: 200px;
  }
}

.map__wrapper {
  min-height: 460px;
  @media (max-width: $screen-xs-max) {
    min-height: 100px;
  }
}

.map__title {
  text-align: center;
  color: $gold;
  @include padding-top($spacing-xl-responsive);
  @include padding-bottom($spacing-lg-responsive);
}

.map__link {
  position: absolute;
  top: 20px;
  left: 20px;
  border: none;
  @media (max-width: $screen-xs-max) {
    top: 10px;
    left: 10px;
    width: 100px;
  }
}

.map__image {
  width: 70%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: $screen-sm-max) {
    width: 90%;
    @include margin-bottom($spacing-xl-responsive);
  }
}

@media (max-width: $screen-xs-max) {
  .w-1,
  .w-2,
  .w-3 {
    width: $article-width;
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
  .w-1,
  .w-2 {
    width: $article-width/2;
  }
  .w-3 {
    width: $article-width;
  }
}

@media (min-width: $screen-lg-min) {
  .w-1 {
    width: $article-width/3;
  }

  .w-2 {
    width: ($article-width/3)*2;
  }

  .w-3 {
    width: $article-width;
  }
}

.border-top {
  border-top: $border-width-lg*2 solid $body-color;
}



/*------------------------------------*\
    #header
\*------------------------------------*/


.header-wrapper {
  z-index: 990;
  background-color: $white;
  // .menu-open.active {
  //   height: calc(100vh - 85px); //hauteur de la fenêtre - hauteur du menu mobile
  //   overflow: scroll;
  // }
  @media (max-width: $screen-xs-max) {
    .menu-open.active {
      height: calc(100vh - 55px); //hauteur de la fenêtre - hauteur du menu mobile
      max-height: calc(100vh - 55px);
      overflow: scroll;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .header--left ul.lang,
  .header--right {
    display: none;
  }
  .header--left {
    display: flex;
    button {
      margin: auto;
      @media (max-width: $screen-sm-max) {
        height: 14px;
        display: block;
        padding: 0;
      }
    }
  }
}

// highlight du menu
body.les-davids.page-id-9 .menu--block:nth-of-type(2) a:not(.link--small),
body.les-davids.page-id-11 .menu--block:nth-of-type(3) a:not(.link--small),
body.les-davids.page-id-13 .menu--block:nth-of-type(4) a:not(.link--small),
body.les-davids.page-id-15 .menu--block:nth-of-type(5) a:not(.link--small),
body.les-davids.page-id-17 .menu--block:nth-of-type(6) a:not(.link--small),
body.lhdd.page-id-49 .menu--block:nth-of-type(2) li:nth-of-type(3) a,
body.lhdd.page-id-113 .menu--block:nth-of-type(2) li:nth-of-type(4) a,
body.lhdd.page-id-117 .menu--block:nth-of-type(2) li:nth-of-type(2) a,
body.lhdd.page-id-787 .menu--block:nth-of-type(2) li:nth-of-type(1) a,
body.lhdd.page-id-119 .menu--block:nth-of-type(3) a, {
  color: $blue;
}




/*------------------------------------*\
    #footer
\*------------------------------------*/

.primary-footer {
  background-color: $gold;
  @include padding-top($spacing-lg-responsive);
  @include padding-bottom($spacing-md-responsive);
  h2,
  p,
  a {
    @include font-size($font-sizes-xs);
    @extend .font-body-bold;
  }
  h2 {
    margin-bottom: 0;
    &:after {
      content: "—";
      display: block;
      @extend .font-body-light;
    }
  }
  p {
    @include margin-bottom($spacing-md-responsive);
  }
  a.icon--link {
    border: none;
    &:not(:last-of-type) {
      margin-right: $spacing-sm;
    }
    &:hover {
      opacity: .6;
    }
  }
  @media (max-width: $screen-md-max) {
    .footer--block {
      @include margin-bottom($spacing-lg-responsive);
    }
  }
}
