/*------------------------------------*\
  #FORMULAIRE-TARIFS
\*------------------------------------*/

.page--formulaire-tarifs {
  background-color: rgba($base-color, .9);
  @extend .booking-modal;
  position: static;
  max-height: none;
  transform: none;
  border: none;
  input.flatpickr-input {
    @extend .form-control;
  }
  @include padding-bottom($spacing-xxl-responsive);
  .booking-contact {
    text-align: center;
    a {
      display: inline-block;
      margin-bottom: .3em;
      border: none;
      text-decoration: underline;
      &:hover {
        text-decoration: underline;
        color: $blue;
      }
    }
  }
  .header--center h2 {
    display: none;
  }
  textarea {
    min-height: 140px;
  }
  .wpcf7-form {
    @include margin-top($spacing-md-responsive);
    @include margin-bottom($spacing-xxl-responsive);
    @include padding-bottom($spacing-md-responsive);
    background-color: rgba($base-color, .8);
    padding: 1em;
    border-top: none;
  }
  ::selection { background: $black; color: $base-color; }
}

.tarif__header,
.formulaire__header {
  padding: 1.5em 0;
  h2 {
    color: $body-color;
    @extend .font-var-semibold;
    @include font-size($font-sizes-lg);
    margin-bottom: 0;
  }
}

.formulaire__header,
.tarif__header {
  border-top: $border-width-sm solid $body-color;
}

.tarif__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


/**
 * tarifs > tableau
 */


.periode__content {
  color: $black;
  h3 {
    margin-bottom: 0;
    line-height: 1;
  }
  .line--basse h3{
    background-color: rgba($orange-4, .4);
  }
  .line--moyenne h3{
    background-color: rgba($orange-3, .4);
  }
  .line--haute h3{
    background-color: rgba($orange-1, .4);
  }
  .line--tres-haute h3{
    background-color: rgba($red-1, .4);
  }
  .line--paques h3{
    background-color: rgba($green-3, .4);
  }
  p {
    font-size: 98%;
  }
  h3,
  p {
    display: inline-block;
  }
  .periode-line {
    margin-bottom: .5em;
  }
}

.tarif__tableau {
  color: $black;
  @include font-size($font-sizes-sm);
  @include margin-top($spacing-lg-responsive);
  @include margin-bottom($spacing-xl-responsive);
  td {
    border: $border-width-sm solid $body-color;
    padding: .5em 1em;
    background-color: $base-color;
    text-align: right;
    &:first-of-type {
      text-align: left;
    }
  }
}

.tarif__tableau:not(.tarif__tableau--periode) {
  @media (max-width: $screen-sm-max) {
    &,
    tbody,
    tr,
    td {
      display: block;
      width: 100%;
      text-align: left;
    }
    thead {
      display: none;
    }
  }
}

td.cellule--titre {
  @extend .font-body-bold;
  background-color: transparent;
  border: none;
  padding-top: 1.4em;
  padding-bottom: .8em;
  @media (max-width: $screen-sm-max) {
    padding-top: 3em;
    border-bottom: $border-width-sm solid $black;
    padding-left: 0;
  }
}

table:not(.tarif__tableau--periode) td.cellule--vide {
  opacity: 0;
  border: none;
}

td.colonne__surtitre {
  text-align: center;
}

td.colonne__titre {
  text-align: center;
  @extend .font-body-bold;
  &:first-of-type {
    text-align: left;
    @extend .font-body-light;
  }
}

td.cellule--logement {
  @media (max-width: $screen-sm-max) {
    padding-top: 1.5em;
    padding-bottom: 1em;
    font-family: $font-body-bold;
    border: none;
    background-color: transparent;
  }
}

td.cellule {
  @media (max-width: $screen-sm-max) {
    margin-top: -1px;
  }
  &:before {
    display: none;
    font-size: 80%;
    margin-bottom: .3em;
    @media (max-width: $screen-sm-max) {
      display: block;
    }
  }
  .small {
    display: block;
    @include font-size($font-sizes-xs);
  }
}

td.cellule--neutre {
  text-align: center;
}

// version mobile
@media (max-width: $screen-sm-max) {
  td.cellule--basse,
  td.cellule--moyenne,
  td.cellule--haute,
  td.cellule--tres-haute,
  td.cellule--paques,
  td.cellule--noel {
    width: calc(50% - 3px) !important;
    display: inline-block !important;
    background-color: $base-color !important;
  }
}

td.cellule--basse {
  background-color: rgba($orange-4, .4);
  &:before {
    content: "Basse"
  }
}

td.cellule--moyenne {
  background-color: rgba($orange-3, .4);
  &:before {
    content: "Moyenne"
  }
}

td.cellule--haute {
  background-color: rgba($orange-1, .4);
  &:before {
    content: "Haute"
  }
}

td.cellule--tres-haute {
  background-color: rgba($red-1, .4);
  &:before {
    content: "Très haute"
  }
}

td.cellule--paques {
  background-color: rgba($green-3, .4);
  &:before {
    content: "Pâques"
  }
}

td.cellule--noel {
  background-color: rgba($blue-1, .4);
  &:before {
    content: "Noël"
  }
}

.tarif__tableau--periode {
  margin-top: 0;
  @include margin-bottom($spacing-md-responsive);
}

.page--formulaire-tarifs {
  .booking-contact {
    margin-bottom: 0;
    color: $blue-1;
    a {
      color: $blue-1;
    }
  }
  section.tarifs,
  section.formulaire {
    padding-top: 85px;
  }
}

// tableau compact en version mobile
@media (max-width: $screen-sm-max) {
  .tarif__tableau--periode {
    td.cellule {
      padding: .3em .6em;
      text-align: left;
      font-size: 75%;
    }
  }
}

.tarif__tableau--simple {
  width: 100%;
  tbody tr {
    @media (max-width: $screen-xs-max) {
      margin-bottom: 1em;
    }
  }
  td:before {
    content: attr(data-label);
  }
  tbody td:not(:first-child) {
    background-color: rgba($orange-4, .4);
  }
}

// Flatpicker
.flatpickr-wrapper:not(.open) {
  display: none;
}
