// ------------------------------------------------------------
// Settings

// :: Colours
// :: Breakpoints
// :: Typography
// :: Borders
// :: Spacing

// ------------------------------------------------------------



/*------------------------------------*\
    #COLOURS
\*------------------------------------*/


// nuances de gris
$white               : #FFFFFF;
$black               : #000000;
$black-cow           : #666666;
$gray                : #999999;
$gray-goose          : #CCCCCC;

$gold                : #906D4E;


$blue-1              : #2BA4B0;
$blue-2              : #006FBA;
$blue-3              : #018491;

$green-1             : #008C50;
$green-2             : #88B833;
$green-3             : #69B285;
$green-4             : #00A87D;
$green-5             : #EDFFCA;

$red-1               : #D32229;
$red-2               : #85322D;
$red-3               : #B2023F;

$pink-1              : #CD0D4A;

$orange-1            : #E43E12;
$orange-2            : #FFA120;
$orange-3            : #FF7338;
$orange-4            : #FFF0CC;

$purple-1            : #641B6A;

$gray-1              : #9A9AA5;
$gray-2              : #A1948F;



// formulaires
$blue                : $blue-1;
$grey                : #999999;
$light-grey          : #e0e0e0;
$light-blue          : #9ACCE2;


// Assign colours to variable roles
// Be descriptive without being specific.
// This allows for consistency project to project, and easy changing

$body-color       : $gold;
$base-color       : $white;
$action           : $white;



/*------------------------------------*\
    #BREAKPOINTS
\*------------------------------------*/


// :: Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  470px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  760px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1270px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

$breakpoints: (
  small    : $screen-sm,
  medium   : 1600px,
  large    : 2000px,
);


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         6% !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet:             calc(720px + $grid-gutter-width) !default;
//** For `$screen-sm-min` and up.
$container-sm:                 $container-tablet !default;

// Medium screen / desktop
$container-desktop:            calc(940px + $grid-gutter-width) !default;
//** For `$screen-md-min` and up.
$container-md:                 $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop:      calc(1140px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop !default;


/*------------------------------------*\
    #FONTS
\*------------------------------------*/


$font-family-body:  "Brown-Light" ,"Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-var:   "Domaine-Display-Regular", Georgia, "Times New Roman", Times, serif !default;
$font-family-mono:  Menlo, Monaco, Consolas, "Courier New", monospace !default;

//----------------------------------

$font-body-bold:           "Brown-Bold" ,"Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-body-bold-italic:    "Brown-Reclinbold" ,"Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-body-light:          "Brown-Light" ,"Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-body-light-italic:   "Brown-Reclinlight" ,"Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-var-regular:         "Domaine-Display-Regular", Georgia, "Times New Roman", Times, serif !default;
$font-var-semibold:        "Domaine-Display-Semibold", Georgia, "Times New Roman", Times, serif !default;


.font-body-bold {
  font-family: $font-body-bold;
}

.font-body-bold-italic {
  font-family: $font-body-bold-italic;
}

.font-body-light {
  font-family: $font-body-light;
}

.font-body-light-italic {
  font-family: $font-body-light-italic;
}

.font-var-regular {
  font-family: $font-var-regular;
}

.font-var-semibold {
  font-family: $font-var-semibold;
}



/*------------------------------------*\
    #TYPESIZE/SCALE
\*------------------------------------*/


$font-size-xs      : 12px;
$font-size-sm      : 17px;
$font-size-md      : 22px;
$font-size-md-alt  : 26px;
$font-size-lg-alt  : 35px;
$font-size-lg      : 45px;
$font-size-xl      : 80px;
$font-size-xxl     : 110px;

//** Unit-less `line-height`
$line-height      : 1.4;
$line-height-alt  : 1.1;
// for use in components like buttons, labels
$line-height-form     : 1.428571429 !default;     // 20/14
// responsive ratio
$font-res-ratio-sm    : 1.25;
$font-res-ratio-md    : 1.4;
$font-res-ratio-lg    : 1.8;



/*------------------------------------*\
    #FONTSIZES
\*------------------------------------*/


$font-sizes-xs: (
  null     : ($font-size-xs, $line-height),
  small    : ($font-size-xs, $line-height),
  medium   : ($font-size-xs * $font-res-ratio-sm, $line-height),
  large    : ($font-size-xs * $font-res-ratio-md, $line-height),
);

$font-sizes-sm: (
  null     : ($font-size-sm, $line-height),
  small    : ($font-size-sm, $line-height),
  medium   : ($font-size-sm * $font-res-ratio-sm, $line-height),
  large    : ($font-size-sm * $font-res-ratio-md, $line-height),
);

$font-sizes-md: (
  null     : ($font-size-md / $font-res-ratio-sm, $line-height),
  small    : ($font-size-md, $line-height),
  medium   : ($font-size-md * $font-res-ratio-sm, $line-height),
  large    : ($font-size-md * $font-res-ratio-md, $line-height),
);

$font-sizes-md-alt: (
  null     : ($font-size-md-alt / $font-res-ratio-sm, $line-height),
  small    : ($font-size-md-alt, $line-height),
  medium   : ($font-size-md-alt * $font-res-ratio-sm, $line-height),
  large    : ($font-size-md-alt * $font-res-ratio-md, $line-height),
);

$font-sizes-lg-alt: (
  null     : ($font-size-lg-alt / $font-res-ratio-md, $line-height-alt),
  small    : ($font-size-lg-alt, $line-height-alt),
  medium   : ($font-size-lg-alt * $font-res-ratio-sm, $line-height-alt),
  large    : ($font-size-lg-alt * $font-res-ratio-md, $line-height-alt),
);

$font-sizes-lg: (
  null     : ($font-size-lg / $font-res-ratio-md, $line-height-alt),
  small    : ($font-size-lg, $line-height-alt),
  medium   : ($font-size-lg * $font-res-ratio-sm, $line-height-alt),
  large    : ($font-size-lg * $font-res-ratio-md, $line-height-alt),
);

$font-sizes-xl: (
  null     : ($font-size-xl / $font-res-ratio-lg, $line-height-alt),
  small    : ($font-size-xl, $line-height-alt),
  medium   : ($font-size-xl * $font-res-ratio-sm, $line-height-alt),
  large    : ($font-size-xl * $font-res-ratio-md, $line-height-alt),
);

$font-sizes-xxl: (
  null     : ($font-size-xxl / $font-res-ratio-lg, $line-height-alt),
  small    : ($font-size-xxl, $line-height-alt),
  medium   : ($font-size-xxl * $font-res-ratio-sm, $line-height-alt),
  large    : ($font-size-xxl * $font-res-ratio-sm, $line-height-alt),
);




/*------------------------------------*\
    #SPACES
\*------------------------------------*/


$spacing-base     : 20px;

$spacing-xxxl     : $spacing-base * 6;  // 120px
$spacing-xxl      : $spacing-base * 4;  // 80px
$spacing-xl       : $spacing-base * 3;  // 60px
$spacing-lg       : $spacing-base * 2;  // 40px
$spacing-md       : $spacing-base;      // 20px
$spacing-sm       : $spacing-base / 2;  // 10px
$spacing-xs       : $spacing-base / 4;  // 5px

$spacing-res-ratio : 1.6;

$spacing-xxxl-responsive: (
  null    : $spacing-xxxl / $spacing-res-ratio,
  small   : $spacing-xxxl,
);

$spacing-xxl-responsive: (
  null    : $spacing-xxl / $spacing-res-ratio,
  small   : $spacing-xxl,
);

$spacing-xl-responsive: (
  null    : $spacing-xl / $spacing-res-ratio,
  small   : $spacing-xl,
);

$spacing-lg-responsive: (
  null    : $spacing-lg / $spacing-res-ratio,
  small   : $spacing-lg,
);

$spacing-md-responsive: (
  null    : $spacing-md / $spacing-res-ratio,
  small   : $spacing-md,
);

$spacing-sm-responsive: (
  null    : $spacing-sm / $spacing-res-ratio,
  small   : $spacing-sm,
);

$spacing-xs-responsive: (
  null    : $spacing-xs / $spacing-res-ratio,
  small   : $spacing-xs,
);


/*------------------------------------*\
    #BORDERS WIDTH
\*------------------------------------*/


$border-width-sm         : 1px;
$border-width-lg         : 3px;



/*------------------------------------*\
    #FUNCTIONAL STUFF
\*------------------------------------*/


@import "functions/functions";
