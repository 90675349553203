/*------------------------------------*\
  #TEMPLATES
\*------------------------------------*/


@media (max-width: $screen-md-max) {
  .section-grid,
  .section-slide {
    background-image: url("../images/image-background.jpg");
    background-color: $body-color;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center bottom;
  }
}

@media (min-width: $screen-lg-min) {
  body.home .page-wrapper{
    background-image: url("../images/image-background.jpg");
    background-color: $body-color;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center bottom;
  }
}

@media (max-width: $screen-xs-max) {
  .masonry {
    position: relative !important;
    height: inherit !important;
    article {
      position: relative !important;
      top: 0 !important;
      left: 0 !important;
    }
  }
}


/*------------------------------------*\
  #Hero tricks
\*------------------------------------*/

@media (min-width: $screen-md-min) {
  .hero {
    z-index: 999;
    position: relative;
    &.hero--out {
      display:none;
    }
    .hero__link:hover,
    .hero__link--small:hover {
      cursor: pointer;
    }
  }
}



/*------------------------------------*\
  #MOBILE-ORDER
\*------------------------------------*/

@media (max-width: $screen-xs-max) {
  .mobile-order {
    display: flex;
    flex-direction: column;
    .mobile-first {
      order: 0;
    }
    & > *:not(.mobile-first) {
      order: 1;
    }
  }
}




/*------------------------------------*\
  #Exceptions
\*------------------------------------*/

h5 + ul li {
  @include font-size($font-sizes-sm);
}

.error404 .header--center h2 {
  display: none;
}

// page les Chambres de Cournille
.page-id-639 {
  .intro {
    margin-bottom: 1em;
  }
}



/*------------------------------------*\
  #Les Davids
\*------------------------------------*/

.header--center h2 {
  margin-bottom: 0 !important;
}
